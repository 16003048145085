import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    useGetCountrySumsQuery,
    useGetCountrySumsRollingWindowQuery,
} from '../../../../redux/services/locations'
import countryData from '../../../../lib/countries.json'
import * as dayjs from 'dayjs'

interface Column {
    id: string
    label: string | any
    minWidth?: number
    align?: 'right'
    borderRight?: string
    borderLeft?: string
    format?: (value: number) => string
}

function RenderRollingWindowData({ year, countryCode }: { year: number; countryCode: string }) {
    const { data } = useGetCountrySumsRollingWindowQuery({ year, countryCode })

    return data?.max_count != null ? <>{data.max_count}</> : <CircularProgress />
}

export default function () {
    const [countryColumns, setCountryColumns] = React.useState<Column[]>([])
    const [years, setYears] = React.useState<number[]>([])
    const [countryCodesSorted, setCountryCodesSorted] = React.useState<string[]>([])
    const [selectedYear, setSelectedYear] = React.useState<number>(dayjs().year())
    const [minEligibleCountForTable, setMinEligibleCountForTable] = React.useState<number>(20)
    const { data } = useGetCountrySumsQuery('')

    const countryCodesMaxCounts = data?.data?.calendar_year
        ? Object.keys(data.data.calendar_year.countries).map((countryCode: string) => {
              return {
                  countryCode,
                  count: data.data.calendar_year.countries[countryCode][selectedYear] || 0,
                  maxCount: Math.max(
                      ...Object.keys(data.data.calendar_year.countries[countryCode]).map(
                          (c) => data.data.calendar_year.countries[countryCode][c]
                      )
                  ),
              }
          })
        : []

    React.useEffect(() => {
        const countryCodeColumns: Column[] = []
        for (const countryCode of countryCodesSorted) {
            const currentCountryData = countryData[countryCode.toUpperCase()]
            countryCodeColumns.push({
                id: countryCode,
                label: (
                    <Tooltip title={currentCountryData?.name}>{currentCountryData?.emoji}</Tooltip>
                ),
                minWidth: 30,
                borderRight: '1px solid black',
            })
        }
        setCountryColumns(countryCodeColumns)
    }, [countryCodesSorted])

    React.useEffect(() => {
        if (data?.data?.calendar_year) {
            setYears([...data.data.calendar_year.years].sort((a, b) => Number(b) - Number(a)))
        }
    }, [data?.data?.calendar_year, countryCodesSorted])

    React.useEffect(() => {
        setCountryCodesSorted(
            (data?.data?.calendar_year
                ? Object.keys(data.data.calendar_year.countries).sort((a: string, b: string) => {
                      return (
                          Math.max(
                              ...Object.keys(data.data.calendar_year.countries[b]).map(
                                  (c) => data.data.calendar_year.countries[b][c]
                              )
                          ) -
                          Math.max(
                              ...Object.keys(data.data.calendar_year.countries[a]).map(
                                  (c) => data.data.calendar_year.countries[a][c]
                              )
                          )
                      )
                  })
                : []
            ).filter((countryCode) => {
                const details = countryCodesMaxCounts.find((o) => o.countryCode === countryCode)
                return details == null || details.count >= minEligibleCountForTable
            })
        )
    }, [data?.data?.calendar_year, minEligibleCountForTable])

    if (!data?.data?.calendar_year) {
        return <CircularProgress />
    }

    const columns = [
        {
            id: 'country',
            label: '',
            minWidth: 200,
            borderRight: '1px solid black',
        },
        ...countryColumns,
    ]

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mx: 'auto' }}>
            <Box
                sx={{
                    my: 2,
                    px: { xs: '4px', md: '16px' },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    maxWidth: '400px',
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedYear}
                        label="Year"
                        onChange={(newYear) => setSelectedYear(Number(newYear.target.value))}
                    >
                        {years.map((year) => {
                            return <MenuItem value={Number(year)}>{year}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <Typography>
                        Minimum days to be included in table: {minEligibleCountForTable}
                    </Typography>
                    <Slider
                        aria-label="Small steps"
                        value={minEligibleCountForTable}
                        getAriaValueText={(value) => `${value}`}
                        step={1}
                        marks
                        min={5}
                        onChange={(e, newValue: any) => setMinEligibleCountForTable(newValue)}
                        max={100}
                        valueLabelDisplay="auto"
                    />
                </Box>
            </Box>
            <TableContainer>
                <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{
                                        borderRight: column?.borderRight,
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover role="checkbox" tabIndex={-1} key={selectedYear}>
                            <TableCell
                                key={selectedYear}
                                align="right"
                                sx={{
                                    borderRight: '1px solid black',
                                }}
                            >
                                Calendar year {selectedYear}
                            </TableCell>
                            {countryCodesSorted.map((countryCode) => {
                                return (
                                    <TableCell
                                        key={countryCode}
                                        align="right"
                                        sx={{
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        {data.data.calendar_year.countries[countryCode][
                                            selectedYear
                                        ] || 0}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={`rolling-${selectedYear}`}
                        >
                            <TableCell
                                key={`rollingcell-${selectedYear}`}
                                align="right"
                                sx={{
                                    borderRight: '1px solid black',
                                }}
                            >
                                Rolling 12 months
                            </TableCell>
                            {countryCodesSorted.map((countryCode) => {
                                return (
                                    <TableCell
                                        key={`rolling-${selectedYear}-${countryCode}`}
                                        align="right"
                                        sx={{
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        <RenderRollingWindowData
                                            year={selectedYear}
                                            countryCode={countryCode}
                                        />
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1} key={`31mar-${selectedYear}`}>
                            <TableCell
                                key={`31marcell-${selectedYear}`}
                                align="right"
                                sx={{
                                    borderRight: '1px solid black',
                                }}
                            >
                                Year ending 31. March
                            </TableCell>
                            {countryCodesSorted.map((countryCode) => {
                                return (
                                    <TableCell
                                        key={`31mar-${countryCode}-${selectedYear}`}
                                        align="right"
                                        sx={{
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        {data.data.mar_31.countries[countryCode][selectedYear] || 0}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1} key={`april-${selectedYear}`}>
                            <TableCell
                                key={`aprilcell-${selectedYear}`}
                                align="right"
                                sx={{
                                    borderRight: '1px solid black',
                                }}
                            >
                                Year ending 5. Arpil
                            </TableCell>
                            {countryCodesSorted.map((countryCode) => {
                                return (
                                    <TableCell
                                        key={`31mar-${countryCode}-${selectedYear}`}
                                        align="right"
                                        sx={{
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        {data.data.apr_5.countries[countryCode][selectedYear] || 0}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1} key={`june-${selectedYear}`}>
                            <TableCell
                                key={`junecell-${selectedYear}`}
                                align="right"
                                sx={{
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                }}
                            >
                                Year ending 30. June
                            </TableCell>
                            {countryCodesSorted.map((countryCode) => {
                                return (
                                    <TableCell
                                        key={`june-${countryCode}-${selectedYear}`}
                                        align="right"
                                        sx={{
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                        }}
                                    >
                                        {data.data.jun_30.countries[countryCode][selectedYear] || 0}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
