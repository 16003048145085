import * as React from 'react'
import Pagewrapper from '../../../../components/PageWrapper'
import AppFooter from '../../../../components/AppFooter'
import { AppHeader } from '../../../../components/AppHeader'
import { useNavigation } from '../../../../actions/navigation'
import { useAuth } from '../../../../hooks/useAuth'
import SumTable from '../../../../components/ComplianceReports/rule183/SumTable'

export default function () {
    const { currentTab, isBackgroundLoading, setIsBackgroundLoading } = useNavigation()
    const [userName, setUserName] = React.useState<string>('')
    const { session } = useAuth('')

    React.useEffect(() => {
        if (!(session && session.user && session.user.name)) return
        setUserName(session.user.name.split(' ')[0] + ' - ')
    }, [session])

    return (
        <Pagewrapper>
            <AppHeader
                isBackgroundLoading={isBackgroundLoading}
                headerText={userName + currentTab}
                userLocationDates={[]}
                setUserLocationDates={() => {}}
            />
            <SumTable />
            <AppFooter />
        </Pagewrapper>
    )
}
